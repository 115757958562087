import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/firstHome.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/firstPage' },
  {
    path: '/',
    component: () => import('../views/firstHome.vue'),
    children: [
      {
        path: '/firstPage',
        component: () => import('../views/firstPage.vue'),
        meta: {
          title: '昱京医学',
        }
      },
      {
        path: '/knowledge',
        component: () => import('../views/knowledge.vue'),
        meta: { title: '昱京医学', keepAlive: true }
      },
      {
        path: '/owner',
        component: () => import('../views/owner.vue'),
        meta: {
          title: '昱京医学',
        }
      },
    ]
  },
  {
    path: '/login',
    component: () => import('../views/login.vue'),
    meta: {
      keepAlive: false,
      meta: { title: '登录' }
    }
  },
  {
    path: '/wxLogin',
    component: () => import('../views/wxLogin.vue'),
    meta: {
      title: '微信授权',
      keepAlive: false
    }
  },
  {
    path: '/everydayPractice',
    component: () => import('../views/everydayPractice.vue'),
    meta: { title: '每日一练' }
  },
  //错题集和
  {
    path: '/errorReview',
    component: () => import('../views/errorReview.vue'),
    meta: {
      title: '错题集合',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/timerExam',
    component: () => import('../views/timerExam.vue'),
    meta: { title: '计时考场' }
  },
  // 我的试卷
  // {
  //   path: '/testPage',
  //   component: () => import('../views/testPage.vue'),
  //   meta: {
  //     title: '我的试卷',
  //     keepAlive: true // 需要缓存
  //   }
  // },
  // 我的题库
  {
    path: '/myStore',
    component: () => import('../views/myStore.vue'),
    meta: {
      title: '我的试卷',
      keepAlive: true // 需要缓存
    }
  },
  // 筛选题库
  // {
  //   path: '/siftStore',
  //   component: () => import('../views/siftStore.vue'),
  //   meta: {
  //     keepAlive: true // 需要缓存
  //   }
  // },
  // 我的订单
  {
    path: '/order',
    component: () => import('../views/order.vue'),
    meta: { title: '我的订单' }
  },
  // 我的收藏
  {
    path: '/myCollect',
    component: () => import('../views/myCollect.vue'),
    meta: {
      keepAlive: true, // 需要缓存
      title: '我的收藏'
    }
  },
  // 学习统计
  {
    path: '/statistics',
    // component: resolve => require(['../views/statistics.vue'], resolve),
    component: () => import('../views/statistics.vue'),
    meta: {
      title: '学习统计',
    }
    // 
  },
  // 无网刷题
  {
    path: '/netless',
    component: () => import('../views/netless.vue')
  },
  // 设置
  {
    path: '/set',
    component: () => import('../views/set.vue'),
    meta: { title: '我的设置' }
  },
  // 个人资料
  {
    path: '/personalData',
    component: () => import('../views/personalData.vue'),
  },
  // 意见反馈
  {
    path: '/feedback',
    component: () => import('../views/feedback.vue'),
    meta: { title: '意见反馈' }
  },
  //会员页面
  {
    path: '/member',
    component: () => import('../views/member.vue'),
    meta: { title: '我的会员' }
  },
  //消息通知
  {
    path: '/inform',
    component: () => import('../views/inform.vue'),
    meta: {
      keepAlive: true,// 需要缓存
      title: '消息通知'
    }
  },
  // 账户安全
  {
    path: '/account',
    component: () => import('../views/account.vue'),
    meta: { title: '账户安全' }
  },
  // 修改信息页
  {
    path: '/modification',
    component: () => import('../views/modification.vue'),
  },
  // 修改信息页
  {
    path: '/examType',
    component: () => import('../views/examType.vue'),
    meta: { title: '切换考试' }
  },
  // 修改信息页
  {
    path: '/timerPage',
    component: () => import('../views/timerPage.vue'),
    meta: { title: '计时考场' }
  },
  // 历史反馈历史记录
  {
    path: '/feedBackHistery',
    component: () => import('../views/feedBackHistery.vue'),
  },
  // 历史反馈历史记录
  {
    path: '/backDetail',
    component: () => import('../views/backDetail.vue'),
    meta: { title: '我的反馈' }
  },
  // 详情
  {
    path: '/analysis',
    component: () => import('../views/analysis.vue'),
    meta: { title: '答案解析' }
    // meta: {
    //   keepAlive: false // 不需要缓存
    // }
  },
  // 纠正
  {
    path: '/redress',
    component: () => import('../views/redress.vue'),
    meta: { title: '题目纠错' }
  },
  // 历年考题页
  {
    path: '/histeryPage',
    component: () => import('../views/histeryPage.vue'),
  },
  // 计时考场结果
  {
    path: '/timerResult',
    component: () => import('../views/timerResult.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  // 我的试卷考试界面
  {
    path: '/myExamPage',
    component: () => import('../views/myExamPage.vue'),
  },
  // 我的试卷考试界面
  {
    path: '/historyResult',
    component: () => import('../views/historyResult.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  // 每日一连结果界面
  {
    path: '/everydayResult',
    component: () => import('../views/everydayResult.vue'),
  },
  // 每日一练结果界面
  {
    path: '/payDetail',
    component: () => import('../views/payDetail.vue'),
  },
  // 支付成功界面
  {
    path: '/paySuccess',
    component: () => import('../views/paySuccess.vue'),
  },
  // 支付成功界面
  {
    path: '/test',
    component: () => import('../views/test.vue'),
  },
  //获得通知详情
  {
    path: '/informDetail',
    component: () => import('../views/informDetail.vue'),
    meta: {
      title: '通知公告',
      keepAlive: false // 需要缓存
    }
  },
  //题目纠错
  {
    path: '/findError',
    component: () => import('../views/findError.vue'),
    meta: { title: '题目纠错' }
  },
  //获得ai组题
  {
    path: '/aiSelect',
    component: () => import('../views/aiSelect.vue'),
    meta: {
      title: 'AI组题',
    }
  },
  //系统消息
  {
    path: '/systemInform',
    component: () => import('../views/systemInform.vue'),
    meta: {
      title: '系统消息',
    }

  },
  //优惠劵
  {
    path: '/coupon',
    component: () => import('../views/coupon.vue'),
    meta: {
      title: '优惠劵',
    }
  },
  //身份认证
  {
    path: '/identity',
    component: () => import('../views/identity.vue'),
    meta: {
      title: '身份认证',
    }
  },
  //发票
  {
    path: '/invoice',
    component: () => import('../views/invoice.vue'),
    meta: {
      title: '开发票',
    }
  },
  //错题解析
  {
    path: '/analysisSwipe',
    component: () => import('../views/analysisSwipe.vue'),
    meta: {
      title: '昱京医学',
    }
  },
  //划重点
  {
    path: '/StuSelect',
    component: () => import('../views/StuSelect.vue'),
    meta: {
      title: '划重点',
    }
  },
  //画中点刷题
  {
    path: '/emphasis',
    component: () => import('../views/emphasis.vue'),
    meta: {
      title: '划重点',
    }
  },
  //画中点刷题
  {
    path: '/everydayTest',
    component: () => import('../views/everydayTest.vue'),

  },
  //清除本地缓存
  {
    path: '/localClear',
    component: () => import('../views/localClear.vue'),

  },
  // 划重点二级
  {
    path: '/chapterSelect',
    component: () => import('../views/chapterSelect.vue'),
  },
  // 划重点显示三级
  {
    path: '/emphasisNew',
    component: () => import('../views/emphasisNew.vue'),
  },
  //获取openid
  {
    path: '/getOpenid',
    component: () => import('../views/getOpenid.vue'),
  },
  // //清除本地缓存
  // {
  //   path: '/book',
  //   component: () => import('../views/book.vue'),

  // },


]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.path == '/statistics') {
//     console.log(store);
//     store.commit('user/updateLoading', true)

//     next()
//   }

//   else {
//     next()
//   }

// })
// 导航守卫
const myCollectRoute = router.options.routes.find(route => route.path === '/myCollect');
const errorReviewRoute = router.options.routes.find(route => route.path === '/errorReview');
const myStoreRoute = router.options.routes.find(route => route.path === '/myStore');

router.beforeEach((to, from, next) => {
  if (to.path == '/inform') {
    switch (to.query.id) {
      case '4':
        to.meta.title = '招聘信息'
        break;
      case '5':
        to.meta.title = '规培信息'
        break;
      case '6':
        to.meta.title = '院校信息'
        break;
      case '7':
        to.meta.title = '岗位信息'
        break;
      default:
        break;
    }
  }
  // 根据路由设置标题
  document.title = to.meta.title || '昱京医学';
  // if (from.path == '/firstPage' && to.path == '/errorReview') {
  //   if (errorReviewRoute) {
  //     errorReviewRoute.meta.keepAlive = true
  //   }
  // }
  if (from.path == '/errorReview' && to.path == '/firstPage') {
    if (errorReviewRoute) {
      errorReviewRoute.meta.keepAlive = false
    }
  }
  else if (from.path == '/errorReview' && to.path == '/analysisSwipe') {
    if (errorReviewRoute) {
      errorReviewRoute.meta.keepAlive = true
    }
  }
  else if (from.path == '/errorReview' && to.path == '/analysis') {
    if (errorReviewRoute) {
      errorReviewRoute.meta.keepAlive = true
    }
  }

  // 我的收藏
  else if (from.path == '/myCollect' && to.path == '/owner') {
    if (myCollectRoute) {
      myCollectRoute.meta.keepAlive = false
    }
  }
  else if (from.path == '/myCollect' && to.path == '/analysis') {
    if (myCollectRoute) {
      myCollectRoute.meta.keepAlive = true
    }
  }
  else if (from.path == '/myCollect' && to.path == '/emphasis') {
    if (myCollectRoute) {
      myCollectRoute.meta.keepAlive = true
    }
  }
  // 我的题库
  else if (from.path == '/myStore' && to.path == '/owner') {
    if (myStoreRoute) {
      myStoreRoute.meta.keepAlive = false
    }
  }
  // 我的题库
  else if (from.path == '/myStore' && to.path == '/knowledge') {
    if (myStoreRoute) {
      myStoreRoute.meta.keepAlive = false
    }
  }
  else if (from.path == '/myStore' && to.path == '/historyResult') {
    if (myStoreRoute) {
      myStoreRoute.meta.keepAlive = true
    }
  }
  else if (from.path == '/myStore' && to.path == '/myExamPage') {
    if (myStoreRoute) {
      myStoreRoute.meta.keepAlive = true
    }
  }
  // else if (from.path == '/inform' && to.path == '/firstPage') {
  //   location.reload();
  // }
  // else if (from.path == '/timerResult' && to.path == '/timerPage') {
  //   location.reload();
  // }



  // else if ((to.path == '/myCollect' && from.path == '/analysis')) {
  //   myCollectRoute.meta.keepAlive = true
  // }
  // else {
  //   // 如果找到了路由，则修改它的 meta.keepAlive 属性
  //   if (myCollectRoute) {
  //     myCollectRoute.meta.keepAlive = false;
  //   }
  // }
  // 确保要调用next()方法
  next();
});
router.afterEach((to, from) => {
  if (from.path == '/examType' && to.path == '/firstPage') {
    location.reload();
  }
})

export default router

import router from "@/router";
import { getUserCode, getExamType, getUtilsType } from "./storage";
import axios from "axios";
import { Toast } from "vant";
// loading.js
export function showLoading() {
  // 清除之前的 setTimeout 计时器
  clearTimeout(showLoading.timeoutId);
  // 设置一个新的 setTimeout 计时器，0.5秒后执行 showLoading
  showLoading.timeoutId = setTimeout(() => {
    const app = document.querySelector('#app');
    const loadingContainer = app.querySelector('.loader');
    loadingContainer.style.display = 'block';
  }, 300);
}
export function hidddenLoading() {
  // 清除之前的 setTimeout 计时器
  clearTimeout(showLoading.timeoutId);
  const app = document.querySelector('#app');
  const loadingContainer = app.querySelector('.loader');
  loadingContainer.style.display = 'none';
}

//创建实例
const instance = axios.create({
  // baseURL: 'http://test.channel.medicine.yujingm.cn',//基地址
  // baseURL: 'http://test.api.medicine.yujingm.cn',//基地址
  baseURL: 'http://api.medicine.yujingm.com', //基地址
  timeout: 10000,
  // headers:{
  //   "Content-Type": "application/json",
  // }
});
// 请求头配置，例如设置Token

//自定义配置
instance.interceptors.request.use(function (config) {
  const usercode = getUserCode() ? getUserCode() : ''
  const defaultHeaders = {
    'appToken': getUserCode(),
    // 可以根据需要添加更多的请求头
  }
  const typeExam = {
    "Content-Type": "application/json",
    'Examtypeid': getExamType(),
    'Classifyid': getUtilsType(),
    // 可以根据需要添加更多的请求头
  }
  const uploadImage = {
    'Content-Type': 'multipart/form-data'
    // 可以根据需要添加更多的请求头
  }

  const feedback = {
    'Content-Type': 'application/x-www-form-urlencoded'
    // 可以根据需要添加更多的请求头
  }
  const json = {
    'Content-Type': 'application/json',
    // 可以根据需要添加更多的请求头
  }
  // 获得题
  if (config.url == '/api/practice/daily/info') {
    config.headers = { ...config.headers, ...defaultHeaders, ...typeExam };
  }
  // 反馈照片
  else if (config.url == '/api/feedback/images') {
    config.headers = { ...config.headers, ...defaultHeaders, ...uploadImage };
  }
  // 提交反馈
  else if (config.url == '/api/feedback/submit') {
    config.headers = { ...config.headers, ...defaultHeaders, ...feedback };
  }
  // 每日一练交卷
  else if (config.url == '/api/practice/daily/examInfo') {
    config.headers = { ...config.headers, ...defaultHeaders, ...typeExam };
  }
  else if (usercode !== '' && config.url !== '/api/practice/daily/info') {
    config.headers = { ...config.headers, ...defaultHeaders };
  }
  else {
    config.headers = { ...config.headers, ...json };
  }
  // Toast.loading({
  //   message: '加载中...',
  //   forbidClick: true,
  //   loadingType: 'spinner',//配置loaing图标
  //   duration: 0, //不会自动消失
  // });
  showLoading()
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

//添加相应拦截器
// Add a response interceptor

instance.interceptors.response.use(function (response) {

  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const res = response.data
  if (res.code == 403) {
    Toast('登录已下线')
    if (router.currentRoute.path !== '/login' && getUserCode()) {
      router.push('/login')
    }
    hidddenLoading()
  }
  else if (res.code == 401) {
    if (!getUserCode()) {
      Toast('欢迎使用昱京医学')
    }
    if (router.currentRoute.path !== '/login') {
      router.push('/login')
    }
    hidddenLoading()
  }

  else if (res.code !== 200) {
    if (res.msg) {
      Toast(res.msg)//错误提示
    }
    else {
      if (getUserCode()) {
        Toast('服务器异常')//错误提示
      }
    }
    console.log(Promise);
    hidddenLoading()
    return Promise.reject(res)
  } else {
    //正确业务逻辑
    hidddenLoading()
  }
  return res;
}, function (error) {
  hidddenLoading()
  Toast('服务器繁忙,请稍后...')
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)

});




//实例导出

export default instance
<template>

  <div id="app">
    <div v-show="isLoading" class="loader"></div>
    <!-- <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet"> -->
    <transition name="opacity" mode="out-in">
      <keep-alive ref="keepAlive">
        <router-view v-wechat-title="$route.meta.title" v-if="$route.meta.keepAlive" />
      </keep-alive>
    </transition>
    <transition name="opacity" mode="out-in">
      <router-view v-wechat-title="$route.meta.title" v-if="!$route.meta.keepAlive" />
    </transition>

  </div>
</template>

<script src="https://res.wx.qq.com/open/js/jweixin-1.6.0.js" />

<script>
export default {
  name: 'App',
  data() {
    return {
      activeName: 'second',
      isLoading: false
     
    };
  },

}
</script>
<style>
/* 禁止复制 */
div {
  user-select: none;
}

/* 点击样式 */
.clickedBox {
  transform: scale(1.05);
  opacity: 0.7;
  /* animation: scaleUp 0.2s forwards; */
}

/* 加载动画 */
.loader {
  width: 7vw;
  padding: 2vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #417aff;
  position: fixed;
  z-index: 5;
  top: 40vh;
  left: 0;
  right: 0;
  margin: auto;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  0% {
    opacity: 0;
    /* 动画开始时，透明度为0 */
    transform: rotate(0turn);
    /* 动画结束时，元素旋转1圈 */
  }

  10% {
    opacity: 1;
    /* 动画结束时，透明度为1 */
    transform: rotate(0.1turn);
    /* 动画结束时，元素旋转1圈 */
  }

  90% {
    opacity: 1;
    /* 动画结束时，透明度为1 */
    transform: rotate(0.9turn);
    /* 动画结束时，元素旋转1圈 */
  }

  100% {
    opacity: 0;
    /* 动画结束时，透明度为1 */
    transform: rotate(1turn);
    /* 动画结束时，元素旋转1圈 */
  }
}

/* 非状态按钮样式 */
.isCheckout {
  background-color: #417aff !important;
}

/* 蒙版 */
.van-overlay {
  background-color: rgba(0, 0, 0, .6);
}

/* 确认框样式 */
.van-dialog {
  border-radius: 3vw !important;
  width: 62vw !important;
  height: 35vw !important;
}

.van-dialog__content--isolated {
  min-height: 23vw !important;
}

/* 顶部文字 */
.van-dialog__header {
  padding-top: 4vw !important;
  font-weight: 550 !important;
  font-size: 4.2vw !important;
}

/* 底部文字 */
.van-dialog__message {
  color: #999999 !important;
  padding: 4vw 0 3.5vw 0 !important;
  font-size: 3.5vw !important;
}

/* 阴影 */
.yinying {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.030);
}

/* 定义进入和离开的过渡效果 */
.opacity-enter-active {
  transition: opacity 0.1s;
}

.opacity-leave-active {
  transition: opacity 0.1s;
}

.opacity-enter,
.opacity-leave-to {
  opacity: 0;
}

/* 轻提示 */
.van-toast {
  /* background-color: rgba(190, 190, 190, 0.507) !important; */
  /* position: relative; */
  /* bottom:40vh; */
  /* border-radius: 15vw; */
}

.z-omit {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* 置顶 */
.goTop {
  width: 9vw;
  height: 9vw;
  border-radius: 50%;
  border: none;
  background-color: #ffffff !important;
  position: fixed;
  right: 3vw;
  bottom: 20vw;
  z-index: 5;
  font-size: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 置顶图片 */
.goTopImg {
  width: 6vw;
  position: relative;
  top: 1vw;
}

/* 置顶文字 */
.goTopFont {
  font-size: 2.5vw;
  color: #666666;
  position: relative;
  bottom: 1vw;
}

/* 按钮 */
.z-button {
  border: none;
  font-size: 3vw;
  border-radius: 10vw;
  padding: 0.5vw 3vw 0.5vw 3vw;
  overflow: hidden;
  transition: all 0.1s ease;
}

/* 认证小盒子抖动动画 */
@keyframes shake {

  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.shake {
  animation: shake 0.52s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

}

/* 滚动隐藏 */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

@keyframes open {
  0% {
    opacity: 1;
    transform: scale(1);

  }

  30% {
    opacity: 0.7;
    transform: scale(1.1);
  }

  60% {
    opacity: 1;
    transform: scale(1);
  }

}

/* 点击样式 */
.clickedBox {
  transform: scale(1.05);
  opacity: 0.7;
  /* animation: scaleUp 0.2s forwards; */
}

.clickedBox2 {
  transform: scale(1.10);
  opacity: 0.7;
  /* animation: scaleUp 0.2s forwards; */
}
</style>
